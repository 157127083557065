function getLocalContent(language, ...args) {
  switch (language) {
    case "en":
      return args[0];
    case "bg":
      return args[1];
    default:
      return args[0];
  }
}
function isVisibleById(id) {
  let element = window.document.getElementById(id);

  return element ? window.getComputedStyle(element).display !== "none" : false;
}
function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}
function showInfoDialog(dispatch, header, content, large, closeFn) {
  dispatch({
    type: "infoDialog",
    payload: {
      header: header,
      content: content,
      large: large,
      closeFn: closeFn,
    },
  });
  if (!isVisibleById("infoDialog")) {
    let infoDialog = document.getElementById("infoDialog");
    infoDialog.classList.add("show");
    infoDialog.setAttribute("aria-hidden", "false");
    infoDialog.setAttribute("style", "display: block");
    infoDialog.focus();

    let mainPage = document.getElementById("mainPage");
    mainPage.classList.add("modal-open");
    mainPage.setAttribute("style", "opacity: 0.3");

    let body = document.body;
    body.classList.add("modal-open");
  }
}
function showConfirmDialog(dispatch, header, content, func, large) {
  dispatch({
    type: "confirmDialog",
    payload: { header: header, content: content, func: func, large: large },
  });
  if (!isVisibleById("confirmDialog")) {
    let infoDialog = document.getElementById("confirmDialog");
    infoDialog.classList.add("show");
    infoDialog.setAttribute("aria-hidden", "false");
    infoDialog.setAttribute("style", "display: block");

    infoDialog.focus();

    let mainPage = document.getElementById("mainPage");
    mainPage.classList.add("modal-open");
    mainPage.setAttribute("style", "opacity: 0.3");

    let body = document.body;
    body.classList.add("modal-open");
  }
}

async function fetchWithRetry(
  endpoint,
  methodType,
  bodyParams,
  formData,
  okFunc = null,
  errorFunc = null,
  headers = {},
  maxRetries = 3,
  retry = 1
) {
  let doRetry = false;
  let response = null;
  try {
    let params = {
      method: methodType,
      headers: {
        ...headers,
        Accept: "application/json",
        //"Content-Type": "application/json",
      },
    };
    if (bodyParams) {
      params.body = JSON.stringify(bodyParams);
    }
    if (formData) {
      params.body = formData;
    }

    response = await fetch(endpoint, params);
    if (response.status === 200 || response.status === 201) {
      const json = await response.json();
      if (okFunc) {
        try {
          okFunc(json);
        } catch (e) {}
      }
      return;
    } else {
      doRetry = true;
    }
  } catch (e) {
    doRetry = true;
  }
  if (doRetry) {
    if (retry < maxRetries) {
      await sleep(1000);
      fetchWithRetry(
        endpoint,
        methodType,
        bodyParams,
        formData,
        okFunc,
        errorFunc,
        headers,
        maxRetries,
        retry + 1
      );
      return;
    }
  }

  if (errorFunc) {
    errorFunc(response);
  }
}

const albumsByCategory = {};

async function getAlbumsByCategory(category, setFetchStatus) {
  if (!albumsByCategory[category]) {
    if (setFetchStatus) {
      setFetchStatus(2);
    }
    return new Promise(async (resolve) => {
      let endpoint = `https://api.svatbenocafe.com/galleries.php?category=${category}`;
      await fetchWithRetry(
        endpoint,
        "get",
        null,
        null,
        async (json) => {
          try {
            if (json && json.length > 0) {
              albumsByCategory[category] = json;
              if (setFetchStatus) {
                setFetchStatus(0);
              }
              resolve(albumsByCategory[category]);
            } else {
              //console.log("no data");
              if (setFetchStatus) {
                setFetchStatus(1);
              }
              resolve([]);
            }
          } catch (e) {
            //console.log("error in getWedingsByCategory: " + e);
            if (setFetchStatus) {
              setFetchStatus(1);
            }
            resolve([]);
          }
        },
        () => {
          //console.log("no weddings");
          if (setFetchStatus) {
            setFetchStatus(1);
          }
          resolve([]);
        }
      );
    });
  } else {
    return albumsByCategory[category];
  }
}

const albums = {};

async function getAlbum(id, setFetchStatus) {
  if (!albums[id]) {
    if (setFetchStatus) {
      setFetchStatus(2);
    }
    return new Promise(async (resolve) => {
      let endpoint = `https://api.svatbenocafe.com/galleries.php?id=${id}`;
      await fetchWithRetry(
        endpoint,
        "get",
        null,
        null,
        async (json) => {
          try {
            if (json) {
              albums[id] = json;
              if (setFetchStatus) {
                setFetchStatus(0);
              }
              resolve(albums[id]);
            } else {
              //console.log("no data");
              if (setFetchStatus) {
                setFetchStatus(1);
              }
              resolve(null);
            }
          } catch (e) {
            //console.log("error in getAlbum: " + e);
            if (setFetchStatus) {
              setFetchStatus(1);
            }
            resolve(null);
          }
        },
        () => {
          //console.log("missing album");
          if (setFetchStatus) {
            setFetchStatus(1);
          }
          resolve(null);
        }
      );
    });
  } else {
    return albums[id];
  }
}

let reviews = [];

async function getReviews(setFetchStatus) {
  if (reviews.length === 0) {
    if (setFetchStatus) {
      setFetchStatus(2);
    }
    return new Promise(async (resolve) => {
      let endpoint = `https://api.svatbenocafe.com/reviews.php`;
      await fetchWithRetry(
        endpoint,
        "get",
        null,
        null,
        async (json) => {
          try {
            if (json && json.length > 0) {
              reviews = json;
              if (setFetchStatus) {
                setFetchStatus(0);
              }
              resolve(reviews);
            } else {
              //console.log("no data");
              if (setFetchStatus) {
                setFetchStatus(1);
              }
              resolve([]);
            }
          } catch (e) {
            //console.log("error in getReviews: " + e);
            if (setFetchStatus) {
              setFetchStatus(1);
            }
            resolve([]);
          }
        },
        () => {
          //console.log("no data");
          if (setFetchStatus) {
            setFetchStatus(1);
          }
          resolve([]);
        }
      );
    });
  } else {
    return reviews;
  }
}

export {
  getLocalContent,
  sleep,
  fetchWithRetry,
  getAlbumsByCategory,
  getAlbum,
  getReviews,
  showInfoDialog,
  showConfirmDialog,
  isVisibleById,
};
