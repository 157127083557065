import React, { createContext, useReducer } from "react";

function getDefaultLanguage() {
  const pathname = window.location.pathname;
  let language = localStorage.getItem("language");
  if (pathname.indexOf("/en") === 0) {
    language = "en";
  } else if (pathname.indexOf("/bg") === 0) {
    language = "bg";
  } else {
    language = localStorage.getItem("language");
  }
  return language ? language : "bg";
}

let initialState = {
  language: getDefaultLanguage(),
  infoDialog: { header: "", content: "" },
  confirmDialog: { header: "", content: "", func: null },
};
let globalContext = createContext(initialState);

let globalContextReducer = (state, action) => {
  switch (action.type) {
    case "language":
      localStorage.setItem("language", action.payload);
      return { ...state, language: action.payload };
    case "infoDialog":
      return { ...state, infoDialog: action.payload };
    case "confirmDialog":
      return { ...state, confirmDialog: action.payload };
    default:
      return state;
  }
};

const GlobalContextProvider = (props) => {
  let [state, dispatch] = useReducer(globalContextReducer, initialState);
  let value = { state, dispatch };

  return (
    <globalContext.Provider value={value}>
      {props.children}
    </globalContext.Provider>
  );
};

export { GlobalContextProvider, globalContext };
