import React, { useContext, useState, Suspense } from "react";
// import {
//   BrowserRouter as Router,
//   Route,
//   NavLink,
//   Redirect,
//   Switch,
// } from "react-router-dom";
import "./App.css";
import { globalContext, GlobalContextProvider } from "./Context.js";
import {
  getLocalContent,
  showInfoDialog,
  // fetchWithRetry,
  // verifyUser,
  // sleep,
} from "./utils.js";
// import { LanguageBar } from "./Navigation.js";
// import logoEn from "./assets/images/logoen.webp";
// import logoBg from "./assets/images/logobg.webp";
// import logoEvent from "./assets/images/logoEvent.webp";
// import logoBeauty from "./assets/images/logoBeauty.webp";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAt,
  faEnvelope,
  // faSignInAlt,
} from "@fortawesome/free-solid-svg-icons";
import { faFacebook, faInstagram } from "@fortawesome/free-brands-svg-icons";

const Modal = React.lazy(() => import("./Modal.js"));
const WeddingCafe = React.lazy(() => import("./WeddingCafe.js"));
const EventCafe = React.lazy(() => import("./EventCafe.js"));
const BeautyCafe = React.lazy(() => import("./BeautyCafe.js"));

function showTermsAndConditions(dispatch, state) {
  import("./terms-and-conditions.js").then((component) => {
    component =
      state.language === "en" ? component.default.en : component.default.bg;
    showInfoDialog(
      dispatch,
      <span className="text-red">
        {getLocalContent(
          state.language,
          "Terms & Conditions",
          "Условия за ползване"
        )}
      </span>,
      component,
      true
    );
  });
}

function showCorporateInformation(dispatch, state) {
  import("./corporate-information.js").then((component) => {
    component =
      state.language === "en" ? component.default.en : component.default.bg;
    showInfoDialog(
      dispatch,
      <span className="text-red">
        {getLocalContent(
          state.language,
          "Corporate Information",
          "Корпоративна информация"
        )}
      </span>,
      component,
      true
    );
  });
}

function showPrivacyPolicy(dispatch, state) {
  import("./privacy-policy.js").then((component) => {
    showInfoDialog(
      dispatch,
      <span className="text-red">
        {getLocalContent(
          state.language,
          "Privacy Policy",
          "Политика за личните данни"
        )}
      </span>,
      component.default,
      true
    );
  });
}

// const Home = (props) => {
//   let { state } = useContext(globalContext);
//   let loadedImages = useRef({ corp: false, wed: false, salon: false });
//   const componentIsMounted = useRef(true);
//   useEffect(() => {
//     return () => {
//       componentIsMounted.current = false;
//     };
//   }, []);

//   useEffect(() => {
//     (async () => {
//       await sleep(1500);
//       let allLoaded = Object.keys(loadedImages.current).reduce(
//         (acc, el) => acc && loadedImages.current[el],
//         true
//       );
//       if (!allLoaded && componentIsMounted.current) {
//         document.getElementById("loadingImgDiv").classList.remove("d-none");
//       }
//       allLoaded = Object.keys(loadedImages.current).reduce(
//         (acc, el) => acc && loadedImages.current[el],
//         true
//       );
//       if (allLoaded && componentIsMounted.current) {
//         document.getElementById("loadingImgDiv").classList.add("d-none");
//       }
//     })();
//     let images = {
//       corp: "/images/corp.png",
//       wed: "/images/wed.png",
//       salon: "/images/salon.png",
//     };
//     Object.keys(images).forEach((key) => {
//       let src = images[key];
//       let img = new Image();
//       img.onload = async (e) => {
//         loadedImages.current[key] = true;
//         document.getElementById(key).style.backgroundImage = `url('${src}')`;
//         let allLoaded = Object.keys(loadedImages.current).reduce(
//           (acc, el) => acc && loadedImages.current[el],
//           true
//         );
//         if (allLoaded && componentIsMounted.current) {
//           document.getElementById("loadingImgDiv").classList.add("d-none");
//           await sleep(100);
//           if (componentIsMounted.current) {
//             document.getElementById("imgDiv").classList.remove("d-none");
//             document.getElementById("corp").classList.add("left-slide");
//             document.getElementById("salon").classList.add("right-slide");
//           }
//         }
//       };
//       img.src = src;
//     });
//   }, []);

//   let logo = state.language === "en" ? logoEn : logoBg;
//   return (
//     <header className="container page home wedding-in-bulgaria">
//       <div className="navbar navbar-expand-md flex-nowrap navbar-red user-select-none ">
//         <div className="flex-grow-1 text-sm-center text-left">
//           {/* <div className="row row-cols-1 row-cols-md-3 align-items-end">
//             <div className="col px-1 d-none d-md-block">
//               <img className="py-1 logo side mr-5" src={logoEvent} alt="Wedding in Bulgaria" />
//             </div>
//             <div className="col px-1"> */}
//           <img
//             className="py-1 logo home mx-sm-5"
//             src={logo}
//             alt="Wedding in Bulgaria"
//           />
//           {/* <div className="slogan font-weight-bold text-red text-wrap ">
//                 {getLocalContent(
//                   state.language,
//                   "Boutique agency for Weddings and Events",
//                   "Бутикова агенция за организиране на събития"
//                 )}
//               </div> */}
//           {/* </div>

//             <div className="col px-1 d-none d-md-block">
//               <img className="py-1 logo side ml-5" src={logoBeauty} alt="Wedding in Bulgaria" />
//             </div>
//           </div> */}
//         </div>
//         <div className="ml-4">
//           <LanguageBar />
//         </div>
//       </div>
//       <div className="fixed-center d-none" id="loadingImgDiv">
//         <div
//           className="spinner-border text-red text-center mx-auto my-5"
//           role="status"
//         >
//           <span className="sr-only">Loading...</span>
//         </div>
//       </div>
//       <div className="row row-cols-1 row-cols-md-3 d-none" id="imgDiv">
//         <div className="col px-1">
//           <NavLink
//             to="/event-cafe"
//             id="corp"
//             exact
//             className="card bg-image height hover mx-auto my-2 justify-content-center"
//             onClick={(e) => {
//               document.activeElement.blur();
//               window.scrollTo(0, 0);
//             }}
//           >
//             <div className="h4 display-on-hover text-center ">
//               {getLocalContent(
//                 state.language,
//                 "For your",
//                 "За вашите"
//               ).toUpperCase()}
//             </div>
//             <h2 className="m-2 text-center">
//               {getLocalContent(
//                 state.language,
//                 "Corporate & cultural events",
//                 "Корпоративни и културни събития"
//               )}
//             </h2>
//           </NavLink>
//         </div>
//         <div className="col px-1">
//           <NavLink
//             to="/wedding-cafe/galleries"
//             id="wed"
//             exact
//             className="card bg-image height hover mx-auto my-2 justify-content-center"
//             onClick={(e) => {
//               document.activeElement.blur();
//               window.scrollTo(0, 0);
//             }}
//           >
//             <div className="h4 display-on-hover text-center ">
//               {getLocalContent(
//                 state.language,
//                 "For your",
//                 "За вашите"
//               ).toUpperCase()}
//             </div>
//             <h2 className="m-2 text-center">
//               {getLocalContent(
//                 state.language,
//                 "Weddings & Personal events",
//                 "Сватби и лични събития"
//               )}
//             </h2>
//           </NavLink>
//         </div>
//         <div className="col px-1">
//           <NavLink
//             to="/beauty-cafe"
//             id="salon"
//             exact
//             className="card bg-image height hover top-pos mx-auto my-2 justify-content-center"
//             onClick={(e) => {
//               document.activeElement.blur();
//               window.scrollTo(0, 0);
//             }}
//           >
//             <div className="h4 display-on-hover text-center ">
//               {getLocalContent(
//                 state.language,
//                 "For your",
//                 "За вашите"
//               ).toUpperCase()}
//             </div>
//             <h2 className="m-2 text-center">
//               {getLocalContent(
//                 state.language,
//                 "Style & Beauty",
//                 "Стил и самочувствие"
//               )}
//             </h2>
//           </NavLink>
//         </div>
//       </div>
//     </header>
//   );
// };

const SITE_TO_USE = "b";

export const useLangPath = () => {
  let { state } = useContext(globalContext);
  return (path) => `/${state.language}${path}`;
};

const MainPage = (props) => {
  const { state, dispatch } = useContext(globalContext);
  const [site] = useState(SITE_TO_USE);
  return (
    <>
      <div id="mainPage">
        {site === "w" && (
          <Suspense
            fallback={
              <div className="container page bg-ash-pink rounded mr-2 blink" />
            }
          >
            <WeddingCafe />
          </Suspense>
        )}
        {site === "c" && (
          <Suspense
            fallback={
              <div className="container page bg-ash-pink rounded mr-2 blink" />
            }
          >
            <EventCafe />
          </Suspense>
        )}
        {site === "b" && (
          <Suspense
            fallback={
              <div className="container page bg-ash-pink rounded mr-2 blink" />
            }
          >
            <BeautyCafe />
          </Suspense>
        )}
        {/* <Router>
        <Switch>
          <Route key="/" exact path="/" component={Home} />
          <Route key="/corporate" path="/event-cafe">
            <EventCafe />
          </Route>
          <Route key="/wedding-cafe" path="/wedding-cafe">
            <WeddingCafe />
          </Route>
          <Route key="/salon" path="/beauty-cafe">
            <BeautyCafe />
          </Route>
          <Redirect to="/"></Redirect>
        </Switch>
      </Router> */}

        <footer
          className={`position-relative footer text-red-light text-center mt-auto py-2 ${
            window.location.pathname === "/admin-manager" ? "d-none" : ""
          }`}
        >
          <div className="container ">
            <div>
              <a
                href={
                  site === "w"
                    ? "https://www.facebook.com/SVATBENOCAFE"
                    : site === "c"
                    ? "https://m.facebook.com/eventcafeboutique"
                    : "https://www.facebook.com/beautycafeboutiquesofia/"
                }
                rel="noopener noreferrer"
                target="_blank"
                className="text-red d-inline-block px-2"
                onClick={(e) => {
                  document.activeElement.blur();
                }}
              >
                <FontAwesomeIcon icon={faFacebook} />
              </a>
              <a
                href="https://www.instagram.com/weddingcafeboutique/"
                rel="noopener noreferrer"
                target="_blank"
                className="text-red d-inline-block pl-1"
                onClick={(e) => {
                  document.activeElement.blur();
                }}
              >
                <FontAwesomeIcon icon={faInstagram} />
              </a>
            </div>
            <div>
              <FontAwesomeIcon icon={faEnvelope} /> office
              <FontAwesomeIcon icon={faAt} />
              eventcafeboutique.com
            </div>
            <div>
              <a
                href="#/"
                className="text-red d-inline-block mx-2"
                style={{ minWidth: "20px" }}
                onClick={(e) => {
                  e.preventDefault();
                  document.activeElement.blur();
                  showTermsAndConditions(dispatch, state);
                }}
              >
                {getLocalContent(
                  state.language,
                  "Terms & Conditions",
                  "Условия за ползване"
                )}
              </a>
              <a
                href="#/"
                className="text-red d-inline-block mx-2"
                style={{ minWidth: "20px" }}
                onClick={(e) => {
                  e.preventDefault();
                  document.activeElement.blur();
                  showPrivacyPolicy(dispatch, state);
                }}
              >
                {getLocalContent(
                  state.language,
                  "Privacy Policy",
                  "Политика за личните данни"
                )}
              </a>
              <a
                href="#/"
                className="text-red d-inline-block mx-2"
                style={{ minWidth: "20px" }}
                onClick={(e) => {
                  e.preventDefault();
                  document.activeElement.blur();
                  showCorporateInformation(dispatch, state);
                }}
              >
                {getLocalContent(
                  state.language,
                  "Corporate Information",
                  "Корпоративна информация"
                )}
              </a>
            </div>
            <div>
              Event Cafe Boutique &copy; 2010-{new Date().getFullYear()} All
              rights reserved.
            </div>

            <div className="developed-by small font-italic">
              Developed by{" "}
              <a
                href="https://www.insentico.com"
                rel="noopener noreferrer"
                target="_blank"
                className="text-red"
              >
                Insentico Ltd.
              </a>
            </div>
            <div className="ads small font-italic d-none">
              <a
                href="https://www.easycryptobot.com"
                rel="noopener noreferrer"
                target="_blank"
                className="text-red"
              >
                easycryptobot
              </a>
            </div>
          </div>
        </footer>
      </div>
      <Suspense
        fallback={<div className="container page bg-ash-pink rounded h-100 mr-2 blink" />}
      >
        <Modal
          id="infoDialog"
          header={state.infoDialog.header}
          content={state.infoDialog.content}
          large={state.infoDialog.large}
          closeFn={state.infoDialog.closeFn}
        />
      </Suspense>
      {/* <ModalConfirm
        id={"confirmDialog"}
        header={state.confirmDialog.header}
        content={state.confirmDialog.content}
        func={() => {
          if (state.confirmDialog.func()) {
            state.confirmDialog.func();
          }
        }}
        large={state.confirmDialog.large}
      /> */}
    </>
  );
};

const App = (props) => {
  return (
    <GlobalContextProvider>
      <MainPage />
    </GlobalContextProvider>
  );
};

export default App;
